import React, { Component } from 'react'

class Image extends Component {
    constructor() {
        super();

        this.state = {
            isLoaded: false
        }
    }

    imageLoaded = () => {
        if (this.props.onLoadedChange) {
            this.props.onLoadedChange(true);
        }
        this.setState({ isLoaded: true });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.src !== this.props.src) {
            if (this.props.onLoadedChange) {
                this.props.onLoadedChange(false);
            }
            this.setState({ isLoaded: false });
        }
    }

    render() {
        return (
            <img className={`${this.props.className}`} src={this.props.src} onLoad={this.imageLoaded} />
        )
    }
}

export default Image