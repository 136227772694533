import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Link } from "react-router-dom"

import Home from './Home.js'

class App extends Component {
    render() {
        return (
            <Router>
                { /* <Link to="/"></Link> */ }
                <div className="app">
                    <div>
                        <Route exact path="/" component={ Home } />
                    </div>
                </div>
            </Router>
        )
    }
}

export default App