import axios from 'axios';

const API_BASE_URL = "https://steni-patterngenerator-api.bettershore-customers.com";
// const API_BASE_URL = "http://localhost:58820";
const APPLICATION_ID = "01730c4b-0ed0-4d26-93b3-274dcec97aad";

export async function downloadPdf(imagePath, architectureName, productName, patternName) {
    return await axios({
        method: "post",
        url: `${API_BASE_URL}/reports`,
        headers: { "Authorization": APPLICATION_ID },
        responseType: "arraybuffer",
        data: {
            imagePath,
            architectureName,
            productName,
            patternName
        }
    });
}


export async function downloadPng(imagePath) {
    return await axios({
        method: "get",
        url: `${API_BASE_URL}/reports/png?`,
        headers: { "Authorization": APPLICATION_ID },
        responseType: "arraybuffer",
        params: {
            imagePath
        }
    });
}