import React, { Component } from 'react'
import classNames from 'classnames'
import { When } from 'react-if'
import ReactModal from 'react-modal'
import fileSaver from 'file-saver'
import $ from "jquery"
import panzoom from "jquery.panzoom"
import ReactPageScroller from "react-page-scroller"
import { isMobile, isTablet } from 'react-device-detect';

import "animate.css/animate.min.css"

import iconArchitecture from './assets/images/icon-architecture.svg'
import iconArchitectureActive from './assets/images/icon-architecture-active.svg'
import iconProduct from './assets/images/icon-product.svg'
import iconProductActive from './assets/images/icon-product-active.svg'
import iconSurface from './assets/images/icon-surface.svg'
import iconSurfaceActive from './assets/images/icon-surface-active.svg'
import iconExport from './assets/images/icon-export.svg'
import iconExportActive from './assets/images/icon-export-active.svg'

import fullscreen from './assets/images/icon-fullscreen.svg'
import zoom from './assets/images/icon-zoom.svg'

import checkmark from './assets/images/checkmark.svg'
import arrowRight from './assets/images/arrow-right.svg'
import arrowRightActive from './assets/images/arrow-right-active.svg'
import arrowDown from './assets/images/scroll-down.svg'
import closeModal from './assets/images/close-modal.svg'
import playIcon from './assets/images/play-icon.svg'

import { downloadPdf, downloadPng } from './utils/api'
import FadeImage from './components/FadeImage/FadeImage'
import Image from './components/Image/Image'

import './Home.scss'

class Home extends Component {
    constructor() {
        super()

        this._pageScroller = null

        this.state = {
            navigationOpen: false,
            tab: 'architecture',
            selectedArchitecture: 'Residential',
            selectedProduct: null,
            selectedPattern: null,
            closeupPattern: null,
            selectedImageZoomVersion: '2x',
            isFullscreen: false,
            exportModalOpen: false,
            closeModalOpen: false,
            videoModalOpen: false,
            downloadingPdf: false,
            downloadingImage: false,
            currentPage: 1,
            isRenderLoaded: false,
            baseImageUrl: 'https://stenipattern.blob.core.windows.net',
            products: [
                {
                    name: 'STENI Vision Standard',
                    filePath: 'vision-standard',
                    patterns: [
                        { name: 'SV 10001 Oak Grey', filePath: 'sv10001' },
                        { name: 'SV 10002 Oak Red', filePath: 'sv10002' },
                        { name: 'SV 10003 Oak Brown', filePath: 'sv10003' },
                        { name: 'SV 10004 Oak Light', filePath: 'sv10004' },
                        { name: 'SV 10005 Zebrano Grey', filePath: 'sv10005' },
                        { name: 'SV 10006 Firewood', filePath: 'sv10006' },
                        { name: 'SV 10007 Chestnut', filePath: 'sv10007' },
                        { name: 'SV 20001 Concrete Dark', filePath: 'sv20001' },
                        { name: 'SV 20002 Concrete Light', filePath: 'sv20002' },
                        { name: 'SV 20003 White Marble', filePath: 'sv20003' },
                        { name: 'SV 20004 Yellow Sandstone', filePath: 'sv20004' },
                        { name: 'SV 20005 Travertine', filePath: 'sv20005' },
                        { name: 'SV 20006 Galvanized Steel', filePath: 'sv20006' },
                        { name: 'SV 20007 Corten', filePath: 'sv20007' },
                    ]
                },
                {
                    name: 'STENI Vision SemiStandard',
                    filePath: 'vision-semistandard',
                    patterns: [
                        { name: 'Zebrano Brown 1', filePath: 'zebrano-brown-1' },
                        { name: 'Zebrano Brown 2', filePath: 'zebrano-brown-2' },
                        { name: 'Text', filePath: 'text' },
                        { name: 'Text Stuc', filePath: 'text-stuc' },
                        { name: 'Two Layer Corten', filePath: 'two-layer-corten' },
                        { name: 'Wallawalla', filePath: 'wallawalla' }, // School default
                        { name: 'Water', filePath: 'water' }, // Office default
                        { name: 'Woods', filePath: 'woods' },
                        { name: 'Woody', filePath: 'woody' },
                        { name: 'Metal Rounds Kaleidoscope Small', filePath: 'metal-rounds-kaleidoscope-small' },
                        { name: 'Metal Rounds Northern Light Shadow', filePath: 'metal-rounds-northern-light-shadow' },
                        { name: 'Metal Rounds Orange Shadow', filePath: 'metal-rounds-orange-shadow' },
                        { name: 'Mosaic', filePath: 'mosaic' },
                        { name: 'Moss', filePath: 'moss' },
                        { name: 'Northern Light', filePath: 'northern-light' }, // Hospital default
                        { name: 'Pearl', filePath: 'pearl' },
                        { name: 'Stone Altered', filePath: 'stone-altered' },
                        { name: 'Stone Altered Blue', filePath: 'stone-altered-blue' },
                        { name: 'Stone Zebrano', filePath: 'stone-zebrano' },
                        { name: 'Marble', filePath: 'marble' },
                        { name: 'Forest', filePath: 'forest' },
                        { name: 'Gradient Blocks', filePath: 'gradient-blocks' },
                        { name: 'Ice Cubes Blue', filePath: 'icecubes-blue' },
                        { name: 'Ice Cubes Orange', filePath: 'icecubes-orange' },
                        { name: 'Knit', filePath: 'knit' },
                        { name: 'Light Yellow Sandstone', filePath: 'light-yellow-sandstone' },
                        { name: 'Flat Travertin', filePath: 'flat-travertin' },
                        { name: 'Fibre', filePath: 'fibre' },
                        { name: 'Fabric', filePath: 'fabric' },
                        { name: 'Earth Ice', filePath: 'earth-ice' }, // House default
                        { name: 'Dimension', filePath: 'dimension' },
                        { name: 'Corten 3D Smaller', filePath: 'corten-3d-smaller' },
                        { name: 'Conolly', filePath: 'conolly' },
                        { name: 'Clouds', filePath: 'clouds' }, // Residental default
                        { name: 'Camouflage', filePath: 'camouflage' },
                        { name: 'Chain', filePath: 'chain' },
                        { name: 'Baroque Gradient', filePath: 'baroque-gradient' },
                        { name: 'Baroque Concrete', filePath: 'baroque-concrete' },
                        { name: 'Bark', filePath: 'bark' },
                        { name: 'Algen', filePath: 'algen' },
                        { name: '3D Star Large', filePath: '3d-star-large' },
                    ]
                },
                {
                    name: 'STENI Nature',
                    filePath: 'nature',
                    patterns: [
                        { name: 'SN20', filePath: 'sn20' },
                        { name: 'SN100', filePath: 'sn100' },
                        { name: 'SN106', filePath: 'sn106' },
                        { name: 'SN111', filePath: 'sn111' },
                        { name: 'SN117', filePath: 'sn117' },
                        { name: 'SN130', filePath: 'sn130' },
                        { name: 'SN140', filePath: 'sn140' },
                        { name: 'SN190', filePath: 'sn190' },
                        { name: 'SN305', filePath: 'sn305' },
                        { name: 'SN315', filePath: 'sn315' },
                        { name: 'SN320', filePath: 'sn320' },
                    ]
                },
                {
                    name: 'STENI Colour',
                    filePath: 'colour',
                    patterns: [
                        { name: 'SN2008', filePath: 'sn2008' },
                        { name: 'SN3413', filePath: 'sn3413' },
                        { name: 'SN4012', filePath: 'sn4012' },
                        { name: 'SN4072', filePath: 'sn4072' },
                        { name: 'SN4350', filePath: 'sn4350' },
                        { name: 'SN4353', filePath: 'sn4353' },
                        { name: 'SN4500', filePath: 'sn4500' },
                        { name: 'SN4537', filePath: 'sn4537' },
                        { name: 'SN5010', filePath: 'sn5010' },
                        { name: 'SN5550', filePath: 'sn5550' },
                        { name: 'SN6510', filePath: 'sn6510' },
                        { name: 'SN6520', filePath: 'sn6520' },
                        { name: 'SN7013', filePath: 'sn7013' },
                        { name: 'SN7020', filePath: 'sn7020' },
                        { name: 'SN7534', filePath: 'sn7534' },
                        { name: 'SN8001', filePath: 'sn8001' },
                        { name: 'SN8002', filePath: 'sn8002' },
                        { name: 'SN8003', filePath: 'sn8003' },
                        { name: 'SN8004', filePath: 'sn8004' },
                        { name: 'SN8005', filePath: 'sn8005' },
                        { name: 'SN8006', filePath: 'sn8006' },
                        { name: 'SN8007', filePath: 'sn8007' },
                        { name: 'SN8008', filePath: 'sn8008' },
                        { name: 'SN8010', filePath: 'sn8010' },
                        { name: 'SN8011', filePath: 'sn8011' },
                        { name: 'SN8013', filePath: 'sn8013' },
                        { name: 'SN8020', filePath: 'sn8020' },
                        { name: 'SN8900', filePath: 'sn8900' },
                        { name: 'SN9100', filePath: 'sn9100' },
                        { name: 'SN9101', filePath: 'sn9101' },
                        { name: 'SN9102', filePath: 'sn9102' },
                        { name: 'SN9103', filePath: 'sn9103' },
                        { name: 'SN9104', filePath: 'sn9104' },
                        { name: 'SN9105', filePath: 'sn9105' },
                        { name: 'SN9106', filePath: 'sn9106' },
                        { name: 'SN9107', filePath: 'sn9107' },
                        { name: 'SN9108', filePath: 'sn9108' },
                        { name: 'SN9109', filePath: 'sn9109' },
                        { name: 'SN9200', filePath: 'sn9200' },
                        { name: 'SN9201', filePath: 'sn9201' },
                        { name: 'SN9202', filePath: 'sn9202' },
                        { name: 'SN9203', filePath: 'sn9203' },
                        { name: 'SN9204', filePath: 'sn9204' },
                        { name: 'SN9205', filePath: 'sn9205' },
                        { name: 'SN9206', filePath: 'sn9206' },
                        { name: 'SN9207', filePath: 'sn9207' },
                        { name: 'SN9300', filePath: 'sn9300' },
                        { name: 'SN9301', filePath: 'sn9301' },
                        { name: 'SN9302', filePath: 'sn9302' },
                        { name: 'SN9303', filePath: 'sn9303' },
                        { name: 'SN9304', filePath: 'sn9304' },
                        { name: 'SN9305', filePath: 'sn9305' },
                        { name: 'SN9306', filePath: 'sn9306' },
                        { name: 'SN9307', filePath: 'sn9307' },
                        { name: 'SN9308', filePath: 'sn9308' },
                        // { name: 'SN9309', filePath: 'sn9309' },
                        { name: 'SN9310', filePath: 'sn9310' },
                        { name: 'SN9400', filePath: 'sn9400' },
                        { name: 'SN9401', filePath: 'sn9401' },
                        { name: 'SN9402', filePath: 'sn9402' },
                    ]
                }
            ]
        }

        this.toggleNavigation = this.toggleNavigation.bind(this)
        this.navigate = this.navigate.bind(this)
        this.setSelectedArchitecture = this.setSelectedArchitecture.bind(this)
        this.setSelectedProduct = this.setSelectedProduct.bind(this)
        this.setSelectedPattern = this.setSelectedPattern.bind(this)
        this.toggleIsFullscreen = this.toggleIsFullscreen.bind(this)
    }

    componentDidMount() {
        this.setState({ selectedProduct: this.state.products[1] })
        this.setState({ selectedPattern: this.state.products[1].patterns[33] })
        this.setState({ closeupPattern: this.state.products[1].patterns[33] })

        $(document).ready(function () {
            const imageWrapper = $(".image-wrapper.pan-scroll")

            imageWrapper.panzoom({
                contain: "invert",
            })

            let zoomCount = 0

            $(".button-zoom").click(() => {
                if (zoomCount == 3) {
                    imageWrapper.panzoom("reset")
                    zoomCount = 0
                }
                else {
                    zoomCount++
                    imageWrapper.panzoom("zoom")
                }

            })
        })
    }

    toggleNavigation() {
        this.setState({ navigationOpen: !this.state.navigationOpen })
    }

    navigate(content) {
        this.setState({ tab: content, navigationOpen: true })
    }

    setSelectedArchitecture(architecture) {
        this.setState({ selectedArchitecture: architecture })

        switch (architecture) {
            case 'Residential':
                this.setState({ selectedProduct: this.state.products[1], selectedPattern: this.state.products[1].patterns[33] })
                break

            case 'School':
                this.setState({ selectedProduct: this.state.products[1], selectedPattern: this.state.products[1].patterns[5] })
                break

            case 'House':
                this.setState({ selectedProduct: this.state.products[1], selectedPattern: this.state.products[1].patterns[29] })
                break

            case 'Hospital':
                this.setState({ selectedProduct: this.state.products[1], selectedPattern: this.state.products[1].patterns[14] })
                break

            case 'Office':
                this.setState({ selectedProduct: this.state.products[1], selectedPattern: this.state.products[1].patterns[6] })
                break
        }
    }

    setSelectedProduct(product) {
        this.setState({ selectedProduct: product, selectedPattern: product.patterns[0] })
        this.navigate('surface')
    }

    setSelectedPattern(pattern) {
        this.setState({ selectedPattern: pattern })
    }

    handleExportModalOpen() {
        this.setState({ exportModalOpen: true })
    }

    handleExportModalClose() {
        this.setState({ exportModalOpen: false })
    }

    handleCloseupModalOpen(pattern) {
        this.setState({ closeupPattern: pattern })
        this.setState({ closeModalOpen: true })
    }

    handleCloseupModalClose() {
        this.setState({ closeModalOpen: false })
    }

    async downloadPdf() {
        this.setState({ downloadingPdf: true })
        const imagePath = `/renders/${this.state.selectedProduct.filePath}/${this.state.selectedPattern.filePath}/Renderings/${this.state.selectedArchitecture}/1x.jpg`
        const result = await downloadPdf(imagePath, this.state.selectedArchitecture, this.state.selectedProduct.name, this.state.selectedPattern.name)
        fileSaver.saveAs(new Blob([result.data], { type: "application/pdf" }), `${this.state.selectedArchitecture}-${this.state.selectedPattern.name}.pdf`)
        this.setState({ downloadingPdf: false })
    }

    async downloadImage() {
        this.setState({ downloadingImage: true })
        const imagePath = `/renders/${this.state.selectedProduct.filePath}/${this.state.selectedPattern.filePath}/Renderings/${this.state.selectedArchitecture}/1x.jpg`
        const result = await downloadPng(imagePath)
        fileSaver.saveAs(new Blob([result.data], { type: "img/png" }), `${this.state.selectedArchitecture}-${this.state.selectedPattern.name}.png`)
        this.setState({ downloadingImage: false })
    }


    toggleIsFullscreen() {
        this.setState({ isFullscreen: !this.state.isFullscreen })
    }

    goToPage = (eventKey) => {
        this._pageScroller.goToPage(eventKey)
    }

    pageOnChange = (number) => {
        $(".toAnimateIn").addClass("animated fadeInUp")
        this.setState({ currentPage: number })
    }

    onLoadingRenderChange = (isLoadingRender) => {
        this.setState({ isRenderLoaded: isLoadingRender })
    }

    render() {
        return (
            <div className="home show-intro">

                <ReactPageScroller ref={c => this._pageScroller = c} pageOnChange={this.pageOnChange} blockScrollUp={this.state.currentPage > 1 || (isMobile && !isTablet)} blockScrollDown={this.state.videoModalOpen || (isMobile && !isTablet)} >
                    <div className="component">
                        <div className="intro">
                            <div className="intro-positioner">
                                <h1 className="intro-title">
                                    <div className="play-icon" onClick={() => this.setState({ videoModalOpen: true })}>
                                        <img className="product-arrow-right" src={playIcon} alt="" />
                                    </div>
                                    <small className="animated fadeInUp">A World of Surfaces</small>
                                    <div className="animated fadeInUp">
                                        Steni pattern generator
                                    </div>                                    
                                </h1>
                                <div className="intro-body animated fadeInUp">                                
                                    <p>
                                        Discover how your building will change by using STENI
                                    </p>

                                    {
                                        isMobile && !isTablet &&    
                                        <p>
                                            Use your desktop/tablet
                                        </p>
                                    }

                                    {
                                        (!isMobile || isTablet) &&
                                        <a href="#" className="nav-link" onClick={() => this._pageScroller.goToPage(1)}>
                                            <span className="nav-link-arrow">
                                                <span className="nav-link-arrow-line" style={ { backgroundImage: `url(${ require("./assets/images/nav-link-arrow-line.svg") })` } }></span>
                                                <span className="nav-link-arrow-icon">
                                                    <img src={ require("./assets/images/nav-link-arrow-icon.svg") } alt=""/>
                                                </span>
                                            </span>
                                            <span className="nav-link-text">EXPLORE THE POSSIBILITIES</span>
                                        </a>
                                    }
                                </div>
                            </div>
                            <div className="intro-backdrop"></div>
                            {
                                this.state.selectedProduct &&
                                this.state.selectedPattern &&
                                <div className="image-wrapper">
                                    <FadeImage className="image" src={`${this.state.baseImageUrl}/renders/vision-semistandard/northern-light/Renderings/Hospital/1x.jpg`} />
                                </div>
                            }
                        </div>
                        <ReactModal
                            isOpen={this.state.videoModalOpen}
                            style={{
                                overlay: {
                                    zIndex: '1000',
                                    backgroundColor: "unset",
                                },
                                content: {
                                    border: "unset",
                                    backgroundColor : "black",
                                    overflowY : "hidden"
                                }
                            }}
                            onRequestClose={() => this.setState({ videoModalOpen: false })}
                            ariaHideApp={false}
                        >
                            <a href="#" onClick={() => this.setState({ videoModalOpen: false })}>
                                <img src={closeModal} />
                            </a>

                            <video controls
                                src={require("./assets/videos/19029_STENI_AnimasjonsfilmKonfigurator_30SEK_V04.mp4")}
                                type="video/mp4"
                                width="100%"
                                height="93%">
                            </video>

                        </ReactModal>
                    </div>

                    <div className="component">

                        <a href="https://www.steni.com" className="action-button button-back button-without-icon" >
                            <span>Back to STENI</span>
                        </a>

                        <div className="bottom-left-buttons">
                            <div className="action-button button-fullscreen" onClick={() => { this.toggleIsFullscreen() }}>
                                <img src={fullscreen} alt="" />
                                <span>FULLSCREEN</span>
                            </div>

                            <div className="action-button button-zoom" >
                                <img src={zoom} alt="" />
                                <span>ZOOM</span>
                            </div>
                        </div>

                        {
                            !this.state.isFullscreen &&                            
                                <div className="sidebar toAnimateIn">
                                    <div className={this.state.isRenderLoaded ? "loader" : "loader active"}>                                        
                                    </div>
                                    <When condition={this.state.tab === 'architecture'}>
                                        <div className="sidebar-content">
                                            <ul className="architecture-list">
                                                <li
                                                    onClick={() => { this.setSelectedArchitecture('Residential') }}
                                                    className={classNames({ 'active': this.state.selectedArchitecture === 'Residential' })}
                                                >
                                                    <When condition={this.state.selectedArchitecture === 'Residential'}>
                                                        <div className="active-overlay"><img src={checkmark} alt="" /></div>
                                                    </When>
                                                    <FadeImage className="image" src={`${this.state.baseImageUrl}/renders/vision-semistandard/clouds/Renderings/Residential/1x.jpg`} />
                                                    <div className="label">Residential</div>
                                                </li>
                                                <li
                                                    onClick={() => { this.setSelectedArchitecture('School') }}
                                                    className={classNames({ 'active': this.state.selectedArchitecture === 'School' })}
                                                >
                                                    <When condition={this.state.selectedArchitecture === 'School'}>
                                                        <div className="active-overlay"><img src={checkmark} alt="" /></div>
                                                    </When>
                                                    <FadeImage className="image" src={`${this.state.baseImageUrl}/renders/vision-semistandard/wallawalla/Renderings/School/1x.jpg`} />
                                                    <div className="label">School</div>
                                                </li>
                                                <li
                                                    onClick={() => { this.setSelectedArchitecture('House') }}
                                                    className={classNames({ 'active': this.state.selectedArchitecture === 'House' })}
                                                >
                                                    <When condition={this.state.selectedArchitecture === 'House'}>
                                                        <div className="active-overlay"><img src={checkmark} alt="" /></div>
                                                    </When>
                                                    <FadeImage className="image" src={`${this.state.baseImageUrl}/renders/vision-semistandard/earth-ice/Renderings/House/1x.jpg`} />
                                                    <div className="label">Private house</div>
                                                </li>
                                                <li
                                                    onClick={() => { this.setSelectedArchitecture('Hospital') }}
                                                    className={classNames({ 'active': this.state.selectedArchitecture === 'Hospital' })}
                                                >
                                                    <When condition={this.state.selectedArchitecture === 'Hospital'}>
                                                        <div className="active-overlay"><img src={checkmark} alt="" /></div>
                                                    </When>
                                                    <FadeImage className="image" src={`${this.state.baseImageUrl}/renders/vision-semistandard/northern-light/Renderings/Hospital/1x.jpg`} />
                                                    <div className="label">Hospital</div>
                                                </li>
                                                <li
                                                    onClick={() => { this.setSelectedArchitecture('Office') }}
                                                    className={classNames({ 'active': this.state.selectedArchitecture === 'Office' })}
                                                >
                                                    <When condition={this.state.selectedArchitecture === 'Office'}>
                                                        <div className="active-overlay"><img src={checkmark} alt="" /></div>
                                                    </When>
                                                    <FadeImage className="image" src={`${this.state.baseImageUrl}/renders/vision-semistandard/water/Renderings/Office/1x.jpg`} />
                                                    <div className="label">Offices</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="sidebar-content-action">
                                            <button className="button" onClick={() => { this.navigate('product') }}>
                                                Choose a product
                                    </button>
                                        </div>
                                    </When>

                                    <When condition={this.state.tab === 'product'}>
                                        <div className="sidebar-content">
                                            <ul className="products-list">
                                                {
                                                    this.state.products.map((product) => {
                                                        return (
                                                            <li
                                                                onClick={() => { this.setSelectedProduct(product) }}
                                                                className={classNames({ 'active': this.state.selectedProduct === product })}
                                                                key={product.name}
                                                            >
                                                                <div className="product-name">{product.name}</div>
                                                                <div className="product-patterns-count">{product.patterns.length} patterns</div>
                                                                <img className="product-arrow-right" src={this.state.selectedProduct === product ? arrowRightActive : arrowRight} alt="" />
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="sidebar-content-action">
                                            {
                                                /*
                                                <button className="button" onClick={ () => { this.navigate('surface') } }>
                                                    Pick a surface
                                                </button>
                                                */
                                            }
                                        </div>
                                    </When>

                                    <When condition={this.state.tab === 'surface'}>
                                        <div className="sidebar-content">
                                            <div className="selected-product-name">
                                                {
                                                    this.state.selectedProduct &&
                                                    this.state.selectedProduct.name
                                                }
                                            </div>
                                            <ul className="patterns-list">
                                                {
                                                    this.state.selectedProduct &&
                                                    this.state.selectedProduct.patterns.map((pattern) => {
                                                        return (
                                                            <li
                                                                className={classNames({ 'active': this.state.selectedPattern === pattern })}
                                                                key={pattern}
                                                            >
                                                                <div onClick={() => { this.setSelectedPattern(pattern) }}>
                                                                    <When condition={this.state.selectedPattern === pattern}>
                                                                        <div className="active-overlay"><img src={checkmark} alt="" /></div>
                                                                    </When>

                                                                    <FadeImage className="pattern-image" src={`${this.state.baseImageUrl}/renders/${this.state.selectedProduct.filePath}/${pattern.filePath}/Button/button.jpg`} alt="" /> 
                                                                </div>

                                                                <div className="pattern-name" onClick={() => { this.setSelectedPattern(pattern) }}>{pattern.name}</div>
                                                                
                                                                <div className="closeup-button" onClick={() => { this.handleCloseupModalOpen(pattern) }}>
                                                                    <span className="closeup-button-arrow">
                                                                        <span className="closeup-button-arrow-line" style={ { backgroundImage: `url(${ require("./assets/images/closeup-button-arrow-line.svg") })` } }></span>
                                                                        <span className="closeup-button-arrow-icon">
                                                                            <img src={ require("./assets/images/closeup-button-arrow-icon.svg") } alt=""/>
                                                                        </span>
                                                                    </span>
                                                                    <span className="closeup-button-text">View close up</span>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="sidebar-content-action">
                                            {
                                                /*
                                                <button className="button" onClick={ () => { this.navigate('export') } }>
                                                    Export
                                                </button>
                                                */
                                            }
                                        </div>
                                    </When>
                                </div>
                        }

                        {
                            !this.state.isFullscreen &&
                            <div className={`navigation toAnimateIn ${this.state.navigationOpen ? 'open' : ''}`}>
                                <button className="navigation-toggler" onClick={this.toggleNavigation}>
                                    <span className="bar"></span>
                                    <span className="bar"></span>
                                    <span className="bar"></span>
                                </button>

                                <ul className="navigation-list">
                                    <li className={classNames({ 'active': this.state.tab === 'architecture' })}>
                                        <a onClick={() => { this.navigate('architecture') }}>
                                            <div className="icon">
                                                <img src={this.state.tab === 'architecture' ? iconArchitectureActive : iconArchitecture} alt="" />
                                            </div>
                                            <span className="label">Choose architecture</span>
                                        </a>
                                    </li>
                                    <li className={classNames({ 'active': this.state.tab === 'product' })}>
                                        <a onClick={() => { this.navigate('product') }}>
                                            <div className="icon">
                                                <img src={this.state.tab === 'product' ? iconProductActive : iconProduct} alt="" />
                                            </div>
                                            <span className="label">Choose product</span>
                                        </a>
                                    </li>
                                    <li className={classNames({ 'active': this.state.tab === 'surface' })}>
                                        <a onClick={() => { this.navigate('surface') }}>
                                            <div className="icon">
                                                <img src={this.state.tab === 'surface' ? iconSurfaceActive : iconSurface} alt="" />
                                            </div>
                                            <span className="label">Pick a surface</span>
                                        </a>
                                    </li>
                                    <li className={classNames({ 'active': this.state.tab === 'export' })}>
                                        <a onClick={() => { this.handleExportModalOpen() }}>
                                            <div className="icon">
                                                <img src={this.state.tab === 'export' ? iconExportActive : iconExport} alt="" />
                                            </div>
                                            <span className="label">Export</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        }

                        {
                            this.state.selectedProduct &&
                            this.state.selectedPattern &&
                            <div className="image-wrapper pan-scroll">
                                <Image className="image" src={`${this.state.baseImageUrl}/renders/${this.state.selectedProduct.filePath}/${this.state.selectedPattern.filePath}/Renderings/${this.state.selectedArchitecture}/${this.state.selectedImageZoomVersion}.jpg`} onLoadedChange={this.onLoadingRenderChange} />
                            </div>
                        }
                    </div>
                </ReactPageScroller>

                <ReactModal
                    isOpen={ this.state.closeModalOpen }
                    className="closeup-modal"
                    overlayClassName="closeup-modal-overlay"
                    onRequestClose={() => this.handleCloseupModalClose()}
                    ariaHideApp={false}
                >
                    <div className="close-modal" onClick={ () => this.handleCloseupModalClose() }>
                        <img src={ closeModal } alt="" />
                    </div>
                    {
                        this.state.selectedProduct &&
                        this.state.closeupPattern &&
                        <div className="closeup" style={ { backgroundImage: `url(${this.state.baseImageUrl}/renders/${this.state.selectedProduct.filePath}/${this.state.closeupPattern.filePath}/Button/button.jpg` } }></div>
                    }
                </ReactModal>

                <ReactModal
                    isOpen={this.state.exportModalOpen}
                    className="export-modal"
                    overlayClassName="export-modal-overlay"
                    onRequestClose={() => this.handleExportModalClose()}
                    ariaHideApp={false}
                >
                    <div className="export-modal-content">
                        <div className="export-header">
                            Export
                        </div>
                        <div>
                            Export your result as a high resolution image and use it for presentations or to share with others
                        </div>
                        <div className="export-options">
                            <button className="button button-pdf" onClick={() => { this.downloadPdf() }} disabled={this.state.downloadingPdf} >
                                {
                                    !this.state.downloadingPdf && <span>Export as PDF</span>
                                }
                                {
                                    this.state.downloadingPdf && <span>Downloading...</span>
                                }
                            </button>
                            <button className="button" onClick={() => { this.downloadImage() }} disabled={this.state.downloadingImage}    >
                                {
                                    !this.state.downloadingImage && <span>Export as PNG</span>
                                }
                                {
                                    this.state.downloadingImage && <span>Downloading...</span>
                                }
                            </button>
                        </div>
                    </div>
                </ReactModal>
            </div>
        )
    }
}

export default Home
