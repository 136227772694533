import React, { Component } from 'react'

import Image from "../Image/Image";

import "./FadeImage.scss"

class FadeImage extends Component {
    constructor() {
        super();

        this.state = {
            isLoaded: false
        }
    }

    onLoad = (isLoaded) => {
        this.setState({isLoaded});
    }

    componentWillReceiveProps(nextProps) {
        // if(nextProps.src != this.props.src) {
        //     this.setState({isLoaded: false});
        // }
    }

    render() {
        const classes = this.state.isLoaded ? "fade-in-image fade-in-image-loaded" : "fade-in-image";
        return (
            // <img className={`${this.props.className} ${classes}`} src={this.props.src} onLoad={this.onLoad} />
            <Image className={`${this.props.className} ${classes}`} src={this.props.src} onLoadedChange={this.onLoad} />
        )
    }
}

export default FadeImage